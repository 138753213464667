import { NavigationLeafColumnStyled } from './NavigationLeaf.style';
import { NavigationColumnCategory } from 'components/Layout/Header/Navigation/NavigationColumnCategory/NavigationColumnCategory';
import { FC } from 'react';
import { NavigationCategoriesColumn, NavigationCategory } from 'types/navigation';

type NavigationLeafProps = {
    columnCategories: NavigationCategoriesColumn[];
};

const TEST_IDENTIFIER = 'layout-header-navigation-navigationleaf-';

export const NavigationLeaf: FC<NavigationLeafProps> = ({ columnCategories }) => {
    const mapColumnsToRows = () => {
        const mappedNavigationColumnsInRows = columnCategories.map((column, index) => {
            const categoriesInRow: NavigationCategory[] = [];
            columnCategories.forEach(
                (columnCategory) =>
                    columnCategory.categories[index] && categoriesInRow.push(columnCategory.categories[index]),
            );

            const navigationCategoriesInRow: NavigationCategoriesColumn = { ...column, categories: categoriesInRow };
            return navigationCategoriesInRow;
        });
        return mappedNavigationColumnsInRows;
    };

    return (
        <>
            {mapColumnsToRows().map((columnCategories, columnIndex) => (
                <NavigationLeafColumnStyled key={columnIndex} data-testid={TEST_IDENTIFIER + columnIndex}>
                    {columnCategories.categories.map((columnCategory, columnCategoryIndex) => (
                        <NavigationColumnCategory key={columnCategoryIndex} columnCategory={columnCategory} />
                    ))}
                </NavigationLeafColumnStyled>
            ))}
        </>
    );
};
