import styled from 'styled-components';

const localVariables = {
    navigationSubListItemGap: '45px',
} as const;

export const NavigationLeafColumnStyled = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 32px;
    padding-left: ${localVariables.navigationSubListItemGap};
`;
