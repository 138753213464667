import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { Navigation } from './Header/Navigation/Navigation';
import { NotificationBars } from './NotificationBars/NotificationBars';
import { Webline } from './Webline/Webline';
import { SeoMeta } from 'components/Basic/Head/SeoMeta/SeoMeta';
import { Adverts } from 'components/Blocks/Adverts/Adverts';
import { FC } from 'react';

type CommonLayoutProps = {
    title?: string | null;
    description?: string | null;
};

export const CommonLayout: FC<CommonLayoutProps> = ({ children, description, title }) => (
    <>
        <SeoMeta title={title} description={description} />
        <NotificationBars />
        <Webline className="relative z-above bg-greyVeryLight">
            <Header />
        </Webline>
        <Webline className="relative mb-8 bg-secondary shadow-sm">
            <Navigation />
        </Webline>
        <Adverts positionName="header" withGapBottom withWebline />
        {children}
        <Adverts positionName="footer" withGapBottom withGapTop withWebline />
        <Webline type="white" className="mt-10 vl:mt-14">
            <Footer />
        </Webline>
    </>
);
