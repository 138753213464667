import { NavigationItem } from './NavigationItem/NavigationItem';
import { useNavigationItems } from 'connectors/navigation/Navigation';
import { FC } from 'react';

const TEST_IDENTIFIER = 'layout-header-navigation';

export const Navigation: FC = () => {
    const navigationItems = useNavigationItems();

    if (navigationItems.length === 0) {
        return null;
    }

    return (
        <ul
            className="relative hidden min-h-[68px] w-full items-end gap-3 vl:flex vl:gap-6"
            data-testid={TEST_IDENTIFIER}
        >
            {navigationItems.map((navigationItem, index) => (
                <NavigationItem navigationItem={navigationItem} key={index} />
            ))}
        </ul>
    );
};
