import { Icon } from 'components/Basic/Icon/Icon';
import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

const localVariables = {
    navigationHeight: '64px',
    navigationSubListItemGap: '45px',
} as const;

type NavigationStyledProps = {
    isOpen?: boolean;
};

export const NavigationItemStyled = styled.li`
    min-height: 68px;
    display: block;
    height: 100%;
`;

export const NavigationItemLinkStyled = styled.a<NavigationStyledProps>(
    ({ theme, isOpen }) => css`
        position: relative;
        margin: 0;
        padding: 12px 0;
        min-height: 68px;

        font-size: ${theme.fontSize.small};
        font-weight: 600;
        color: ${theme.color.white};
        text-decoration: none;
        height: 100%;
        display: flex;
        align-items: center;

        ${isOpen &&
        css`
            color: ${theme.color.primary};
            text-decoration: none;
        `};

        @media ${theme.mediaQueries.queryVl} {
            font-size: ${theme.fontSize.default};
        }

        &:hover {
            text-decoration: none;

            &:after {
                display: block;
            }
        }

        &:after {
            content: '';
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 6px;
            border-radius: ${theme.radius.small} ${theme.radius.small} 0 0;

            ${isOpen &&
            css`
                display: block;
            `};

            background-color: ${theme.color.primary};
        }
    `,
);

export const NavigationItemLinkIconStyled = styled(Icon)<NavigationStyledProps>(
    ({ theme, isOpen }) => css`
        margin-right: 8px;
        min-width: 12px;
        width: 12px;

        ${isOpen
            ? css`
                  transform: rotate(180deg);
                  color: ${theme.color.orangeLight};
              `
            : css`
                  color: ${theme.color.greyLighter};
              `};
    `,
);

export const NavigationItemSubStyled = styled.div<NavigationStyledProps>(
    ({ theme, isOpen }) => css`
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        z-index: ${theme.zIndex.menu};
        padding: 50px 28px 45px 60px;

        background: ${theme.color.white};
        box-shadow: 0 5px 10px 0 rgba(164, 167, 193, 0.34);
        opacity: 0;
        pointer-events: none;

        ${isOpen &&
        css`
            opacity: 1;
            pointer-events: auto;
        `};
    `,
);

export const NavigationItemSubWrapStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -${localVariables.navigationSubListItemGap};
`;
